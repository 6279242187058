import ApplicationController from "./application_controller"
/**
 * Site Search Controller.
 *
 * Controls the submenu visibility and
 * scrollability responsively.
 *
 * @since 22.12
 */
export default class extends ApplicationController {
  static targets = ["input"]

  validateText() {
    if (this.inputTarget.value) {
      this.inputTarget.classList.add(this.inputValueClass)
      this.searchTarget.classList.remove(this.buttonEmptyClass)
    } else {
      this.searchTarget.classList.add(this.buttonEmptyClass)
    }
  }
}
