import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["secondaryItems"]
  static classes = ["visible"]

  /**
   * Enhance showing of secondary menu by delaying the closing of it to allow mouse users to move to a
   * secondary item without the menu closing before they reach it (as they will leave :hover state). Menu should
   * still behave without this enhancement on pure css
   **/
  openSecondary () {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = undefined
    }

    if (this.hasSecondaryItemsTarget) {
      this.secondaryItemsTarget.classList.add(this.visibleClass)
    }
  }

  closeSecondary () {
    if (this.hasSecondaryItemsTarget) {
      this.timeout = setTimeout(() => {
        this.secondaryItemsTarget.classList.remove(this.visibleClass)
      }, 500)
    }
  }
}
