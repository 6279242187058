import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["copyLink"]
  static values = {
    type: String,
    url: String,
    copyLinkCopied: String
  }

  // Google Tag Manager will listen for the 'social-share' event
  trackShareEvent (eventObj) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(eventObj)
  }

  openPopup (url, popupWidth, popupHeight) {
    // https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY
    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height
    const systemZoom = width / window.screen.availWidth
    const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft
    const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop
    window.open(
      url,
      "share",
      `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,width=${popupWidth},height=${popupHeight},top=${top},left=${left}`
    )
  }

  shareFacebook (event) {
    this.trackShareEvent({
      event: "social-share",
      social_platform: "facebook"
    })

    if (event) event.preventDefault()
    window.FB.ui(
      {
        href: this.urlValue,
        display: "popup",
        method: "share"
      },
      function () {}
    )
  }

  shareTwitter (event) {
    this.trackShareEvent({
      event: "social-share",
      social_platform: "twitter"
    })

    if (event) event.preventDefault()
    this.openPopup(event.srcElement.href, 650, 700)
  }

  shareEmail (event) {
    this.trackShareEvent({
      event: "social-share",
      social_platform: "email"
    })

    if (event) event.preventDefault()
    this.openPopup(event.srcElement.href, 800, 600)
  }

  copyLink (event) {
    this.trackShareEvent({
      event: "article-button-click",
      article_title: `${document.title}`
    })

    if (event) event.preventDefault()

    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.urlValue)
    } else {
      // No clipboard support, fall back to old execCommand implementation
      const dummyTextElement = document.createElement("input")
      document.body.appendChild(dummyTextElement)
      dummyTextElement.value = this.urlValue
      dummyTextElement.select()
      document.execCommand("copy")
      document.body.removeChild(dummyTextElement)
    }
    if (this.hasCopyLinkCopiedValue) {
      this.copyLinkTarget.innerHTML = this.copyLinkCopiedValue
    }
  }
}
