import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    type: String
  }

  connect () {
    if (this.isPreview) return

    if (!this.script) {
      this.addScript()
    }
  }

  turboBeforeCache () {
    this.cleanScript()
  }

  get scriptId () {
    return `${this.typeValue}-script`
  }

  get script () {
    return window.document.getElementById(this.scriptId)
  }

  addScript () {
    const scriptElement = window.document.createElement("script")
    switch (this.scriptId) {
      case "twitter-script":
        scriptElement.src = "https://platform.twitter.com/widgets.js"
        break
      case "instagram-script":
        scriptElement.src = "https://www.instagram.com/embed.js"
        break
    }
    scriptElement.id = this.scriptId
    window.document.head.appendChild(scriptElement)
  }

  cleanScript () {
    if (this.script) {
      window.document.head.removeChild(this.script)
    }
  }
}
