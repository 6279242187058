import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

export const setupStimulus = () => {
  const application = Application.start()
  const context = require.context("../controllers", true, /.*controller\.js$/)
  application.load(definitionsFromContext(context))

  // https://github.com/hotwired/stimulus/issues/104
  const turboStimulus = (event, functionName) => {
    document.addEventListener(event, function () {
      application.controllers.forEach(function (controller) {
        if (typeof controller[functionName] === "function") {
          controller[functionName]()
        }
      })
    })
  }

  turboStimulus("turbo:before-cache", "turboBeforeCache")
}
