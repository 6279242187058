import ApplicationController from "./application_controller"
import { useResize } from "stimulus-use"

/**
 * Secondary Menu Controller.
 *
 * Controls the visibility and
 * scrollability of the menu items.
 *
 * @since 22.12
 */
export default class extends ApplicationController {
  static classes = ["visible", "invisible"]

  static targets = ["next", "previous", "container"]

  static values = {
    scrollBy: Number
  }

  connect() {
    useResize(this)
    this.maybeShouldScroll()
  }

  resize() {
    this.maybeShouldScroll()
  }

  maybeShouldScroll() {
    const shouldScroll = this.containerTarget.scrollWidth > this.containerTarget.offsetWidth

    this.toggleActionVisibility(
      this.nextTarget,
      shouldScroll &&
        this.containerTarget.scrollWidth > this.containerTarget.clientWidth + this.containerTarget.scrollLeft
    ).toggleActionVisibility(this.previousTarget, shouldScroll && this.containerTarget.scrollLeft > 0)

    return this
  }

  toggleActionVisibility(target, show) {
    if (show) {
      target.classList.remove(...this.invisibleClasses)
      target.classList.add(...this.visibleClasses)
    } else {
      target.classList.add(...this.invisibleClasses)
      target.classList.remove(...this.visibleClasses)
    }

    return this
  }

  scroll({ params: { direction } }) {
    switch (direction) {
      case "next":
        this.next().maybeShouldScroll()
        break
      case "previous":
        this.previous().maybeShouldScroll()
        break
    }
  }

  next() {
    const scrollForward = this.containerTarget.scrollWidth - this.containerTarget.offsetWidth
    this.containerTarget.scrollLeft += scrollForward < this.scrollBy ? scrollForward : this.scrollByValue
    return this
  }

  previous() {
    const scrollBack = this.containerTarget.scrollLeft - this.scrollByValue

    this.containerTarget.scrollLeft = this.containerTarget.scrollLeft < this.scrollByValue ? 0 : scrollBack
    return this
  }
}
