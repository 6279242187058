import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["parent"]

  connect () {
    if (this.isPreview) return
    this.setupVideoSize()
  }

  setupVideoSize () {
    const videoFrame = this.parentTarget.getElementsByTagName("iframe")[0]
    const width = videoFrame.getAttribute("width")
    const height = videoFrame.getAttribute("height")
    const aspectRatio = height / width
    const parentDiv = this.parentTarget

    parentDiv.style.paddingBottom = aspectRatio * 100 + "%"
    videoFrame.removeAttribute("height")
    videoFrame.removeAttribute("width")
  }
}
