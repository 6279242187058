import { reloadDisqus } from "./helpers/disqus"
import { setupGoogletag, setupTargeting } from "./helpers/googletag"
import { setupGtm, setGtmPageViewEvent } from "./helpers/gtm"
import { setupSentry } from "./helpers/sentry"
import { setupStimulus } from "./helpers/stimulus"
import { setupTurbo } from "./helpers/turbo"
import { pruneBonzai, setupTruskinObserver } from "./helpers/bonzai"
import { messageListener } from "./helpers/messages"
import { workaroundWebkitBug } from "./helpers/workarounds"

setupSentry()
setupGtm()
setupGoogletag()
setupTurbo()
setupStimulus()
document.addEventListener("turbo:before-cache", pruneBonzai)
document.addEventListener("turbo:load", setupTargeting)
document.addEventListener("turbo:load", setupTruskinObserver)
document.addEventListener("turbo:load", reloadDisqus)
document.addEventListener("turbo:load", workaroundWebkitBug)
document.addEventListener("turbo:load", setGtmPageViewEvent)
window.addEventListener("message", messageListener)
