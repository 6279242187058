import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  get isPreview () {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }

  hasFeature (feature) {
    return !!localStorage.getItem(feature)
  }
}
