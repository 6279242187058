import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["modal", "iframe"]

  openModal() {
    const el = this.modalTarget
    el.classList.remove("hidden")
    el.classList.add("opacity-100")
    this.play()
  }

  play() {
    this.exec("playVideo")
  }

  stop() {
    this.exec("stopVideo")
  }

  closeModal() {
    const el = this.modalTarget

    this.stop()
    el.classList.add("opacity-0")

    setTimeout(() => {
      el.classList.add("hidden")
      el.classList.remove("opacity-100")
    }, 200)
  }

  exec(func) {
    const message = JSON.stringify({
      event: "command",
      func: func,
      args: []
    })

    this.iframeTarget.contentWindow.postMessage(message, "*")
  }
}
