import { useMemo } from "stimulus-use"
import ApplicationController from "./application_controller"
/**
 * GTM Publisher reference
 * https://developers.google.com/publisher-tag/reference
 */

export default class extends ApplicationController {
  refreshes = {}

  currentAdvertiser = null

  refreshQueued = false
  inView = false
  withInview = false
  static values = {
    adOptions: Object,
    adSize: Array,
    adSizeMapping: Array,
    adRefresh: Array
  }

  static memo = {
    adRefreshConfigs: Array
  }

  static classes = ["empty"]

  connect() {
    this.currentWidth = this.desiredWidth

    window.addEventListener("message", this.teadsListener.bind(this.element))
    useMemo(this)
  }

  get adRefreshConfigs() {
    return this.adRefreshValue.map((adRefresh) => {
      const [advertiserId, refresh, interval, inview] = adRefresh.split("|")
      return {
        advertiserId: parseInt(advertiserId) || advertiserId,
        refresh: parseInt(refresh),
        interval: parseInt(interval),
        inview: parseFloat(inview) || false
      }
    })
  }

  /**
   * Helper to remove Google SafeFrame heights for Teads ads.
   *
   * @param {object} event
   */
  teadsListener(event) {
    if (event.data === "teads:initialised") {
      const safeFrameWrapper = this.firstChild

      const safeFrame = safeFrameWrapper?.firstChild
      if (safeFrame && safeFrame.height > parseInt(safeFrameWrapper?.style?.height || 0)) {
        safeFrameWrapper.style.height = "auto"
      }
    }
  }

  disconnect() {
    this.destroy()
    this.clearTimer()
    window.removeEventListener("message", this.teadsListener)
  }

  slotRenderedEvent(event) {
    if (this.element.id === event.slot.getSlotElementId()) {
      this.currentAdvertiser = this.adRefreshConfigs.find(
        (item) => item.advertiserId === (event.isBackfill ? "backfill" : event.advertiserId)
      )

      if (!this.currentAdvertiser) {
        return
      }

      this.refreshes[this.currentAdvertiser.advertiserId] = this.refreshes[this.currentAdvertiser.advertiserId] ?? 0

      if (this.refreshes[this.currentAdvertiser.advertiserId] < this.currentAdvertiser.refresh) {
        // console.table({
        //   isBackfill: event.isBackfill,
        //   slot: event.slot.getSlotElementId(),
        //   ...this.currentAdvertiser
        // })

        if (this.currentAdvertiser.inview) {
          this.withInview = true

          this.intersection = new IntersectionObserver(this.inviewRefresh.bind(this), {
            threshold: new Array(100 / 5 + 1).fill(5).map((val, index) => (val * index) / 100)
          })

          this.intersection.observe(this.element)
        }

        setTimeout(this.queueRefresh.bind(this), this.currentAdvertiser.interval)
      }
    }

    if (googletag.pubads) googletag.pubads().removeEventListener("slotRenderEnded", this.slotRenderedEvent)
  }

  queueRefresh() {
    if (this.withInview && !this.inView && this.currentAdvertiser.inview) {
      this.refreshQueued = true
    } else {
      this.refreshAdSlot()
    }
  }

  inviewRefresh(e) {
    this.inView = e[0].isIntersecting

    if (this.refreshQueued && (this.inView || e[0].intersectionRatio >= this.currentAdvertiser.inview)) {
      this.refreshAdSlot()
    }
  }

  refreshAdSlot() {
    this.refreshes[this.currentAdvertiser.advertiserId] += 1
    if (googletag.pubads) googletag.pubads().refresh([this.slot])
    this.refreshQueued = false
    this.currentAdvertiser = null
  }

  show() {
    if (!this.slot) {
      googletag.cmd.push(() => {
        if (this.adRefreshConfigs.length > 0) {
          googletag.pubads().addEventListener("slotRenderEnded", this.slotRenderedEvent.bind(this))
        }

        this.slot = googletag.defineSlot(this.adOptionsValue.unitPath, this.adSizeValue, this.element.id)
        const [slotName, slotNumber] = this.element.id.split("-") // assuming its "<slotName>-<slotNumber>"

        if (this.adSizeMappingValue) {
          const mapping = googletag.sizeMapping()
          this.adSizeMappingValue.forEach((size) => {
            mapping.addSize(...size)
          })
          const mappingBuild = mapping.build()
          this.slot.defineSizeMapping(mappingBuild)
        }

        this.slot.setTargeting("slotname", slotName)
        this.slot.setTargeting("slotnumber", slotNumber)
        this.slot.addService(googletag.pubads())

        googletag.enableServices()
        googletag.display(this.element.id)
      })
    }
  }

  destroy() {
    if (this.slot) {
      googletag.cmd.push(() => {
        googletag.destroySlots([this.slot])
      })
      this.slot = undefined
    }
  }

  hide() {
    this.element.classList.add(this.emptyClass)
  }

  resize() {
    const iframe = this.element.querySelector("iframe")
    const id = this.element.id
    if (iframe) {
      if (id.startsWith("cta")) {
        iframe.removeAttribute("height")
        iframe.removeAttribute("width")
      } else if (id.startsWith("stickycta")) {
        const adHeight = 50
        const adHeightPx = `${adHeight}px`
        const spacer = "--spacer-sticky-ad"
        // cta ads are responsive, but are targetted by having dimensions of 3x2.
        // This overrides the dummy values with a value based on the width of the window
        // We are also using the same ad spot to show 320x50 banner ads, which are fixed size
        // so we only want to recalculate the height and width for the CTAs.
        // TODO(aspinks): Is there a way we clean this up when we redo the CTA creatives?
        if (iframe.width === "3" && iframe.height === "2") {
          iframe.width = window.innerWidth - 32
          iframe.height = iframe.contentWindow.document.body.scrollHeight
          iframe.onload = (e) => {
            const frame = e.currentTarget
            frame.height = frame.contentWindow.document.body.scrollHeight
          }
        }
        this.element.style.height = adHeightPx
        document.documentElement.style.setProperty(spacer, adHeightPx)
      }
    }

    window.addEventListener("message", (messageEvent) => {
      // First, check if origin is trusted.
      const trustedHosts = [
        "https://www.whichcar.com.au",
        "https://staging.whichcar1.com.au",
        "https://test1.dev.whichcar1.com.au",
        "https://test2.dev.whichcar1.com.au",
        "http://localhost:3000",
        "http://127.0.0.1:3000"
      ]

      if (
        (!messageEvent.isTrusted && window.location.hostname === "www.whichcar.com.au") ||
        !trustedHosts.includes(messageEvent.origin)
      ) {
        return
      }

      // Make sure data is an object and has an action and signature.
      const data = messageEvent.data
      if (typeof data !== "object" || !data.action || !data.signature) {
        // Turns out many unrelated messages are sent to the window,
        // so don't error out, just return quietly.
        return
      }

      const cookieKey = `wheels-sticky-ad--${data.signature}`
      const currentTimestamp = new Date().getTime()

      // If it's a load event...
      if (data.action === "load") {
        // Is there a key for this signature?
        const cookieTimestamp = localStorage.getItem(cookieKey)

        const ttl = 30 * 24 * 60 * 60 * 1000 // 30 days.
        const timestampExpired = currentTimestamp - cookieTimestamp > ttl

        // If there is a key and it's not expired...
        if (cookieTimestamp && !timestampExpired) {
          // Hide the ad.
          this.hide()
        }

        // If the key is expired...
        if (cookieTimestamp && timestampExpired) {
          // Remove the key.
          localStorage.removeItem(cookieKey)
        }

        // Remove the event listener.
        window.removeEventListener("message", this.resize)

        return
      }

      if (data.action === "close") {
        // Otherwise, a close event...

        // Set a cookie key for this signature.
        localStorage.setItem(cookieKey, currentTimestamp)

        // Hide the ad.
        this.hide()

        // Remove the event listener.
        window.removeEventListener("message", this.resize)
      }
    })
  }

  refresh() {
    this.clearTimer()

    if (this.slot && this.currentWidth !== this.desiredWidth) {
      this.setTimer()
    }
  }

  setTimer() {
    this.debounceTimer = setTimeout(() => {
      googletag.cmd.push(() => {
        googletag.pubads().refresh([this.slot])
      })

      this.currentWidth = this.desiredWidth
      this.debounceTimer = undefined
    }, 250)
  }

  clearTimer() {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer)
    }
  }

  get desiredWidth() {
    return this.adSizeMappingValue
      .map(([[width]]) => width)
      .sort((a, b) => b - a)
      .find((w) => w <= window.innerWidth)
  }
}
