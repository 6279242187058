import { getCookie, getKey, getValues } from "./aamgpt"

export const setupGoogletag = () => {
  window.googletag.cmd.push(() => {
    // Lazy loading for ads
    googletag.pubads().enableLazyLoad({
      // Fetch slots within 3 viewports.
      fetchMarginPercent: 300,
      // Render slots within 2 viewports.
      renderMarginPercent: 200,
      // Increase the above values on mobile, where viewports are smaller
      // and users tend to scroll faster.
      mobileScaling: 1.5
    })

    googletag.pubads().addEventListener("slotRenderEnded", (event) => {
      const slotId = event.slot.getSlotElementId()
      const slot = document.getElementById(slotId)
      // Articles tagged with adfreepage, should not show ads. adfreepage uses `3x3` ads
      // defined in `adfreepageNoAd` ad.ts
      if (event.isEmpty || (event.size[0] === 3 && event.size[1] === 3)) {
        slot.dispatchEvent(new CustomEvent("googletag:hide"))
      } else {
        slot.dispatchEvent(new CustomEvent("googletag:resize"))
      }
    })
  })
}

export const setupTargeting = () => {
  window.googletag.cmd.push(() => {
    googletag.pubads().clearTargeting()
    googletag.pubads().setTargeting("ENV", window.config.googletag.env)
    if (typeof getCookie("bauer_gpt") !== "undefined") {
      googletag.pubads().setTargeting(getKey("bauer_gpt"), getValues("bauer_gpt"))
    }
    if (typeof getCookie("aam_uuid") !== "undefined") {
      googletag.pubads().setTargeting("aamId", getCookie("aam_uuid"))
    }

    for (const prop in window.gptTargeting) {
      googletag.pubads().setTargeting(prop.toLowerCase(), window.gptTargeting[prop])
    }

    window.document.dispatchEvent(new CustomEvent("googletag:show"))
  })
}
