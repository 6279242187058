import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    identifier: String, // element ID to scroll into view
    behavior: String, // smooth or auto, defaults to smooth
    yoffset: Number // scroll offset, negative to scroll back a little, defaults to 0
  }

  get domElement () {
    return window.document.getElementById(this.identifierValue)
  }

  scroll (event) {
    if (this.domElement) {
      if (event) event.preventDefault()
      const yOffset = this.yoffsetValue || 0
      const yPosition = this.domElement.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: yPosition, behavior: this.behaviorValue || "smooth" })
    }
  }
}
