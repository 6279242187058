import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

const ignoreUrls = [
  "https://cdn-gl.imrworldwide.com/",
  "https://e.infogram.com/",
  "https://massets.bonzai.co/",
  "https://sb.scorecardresearch.com/",
  "https://securepubads.g.doubleclick.net/",
  "https://www.googleadservices.com/",
  "https://www.googletagmanager.com/",
  "https://www.instagram.com/",
  "https://xd.wayin.com/"
]

export const setupSentry = () => {
  Sentry.init({
    ...window.config.sentry,
    integrations: [
      new Integrations.BrowserTracing()
    ],
    beforeSend: (event) => {
      // https://github.com/getsentry/sentry-javascript/issues/1811
      // whenever a link is shared by Facebook or Messenger, Facebook crawls the link
      // but their crawler creates "TypeError: Illegal invocation" on HTMLDocument.document.createEvent
      // This doesn't happen on real devices, so we disable Sentry for Facebook crawlers
      // See also https://github.com/aFarkas/lazysizes/issues/520
      if (location.search.indexOf("fbclid") !== -1) return null

      // https://github.com/getsentry/sentry-javascript/issues/3045
      // denyUrls work at one end of the stack trace, we test the entire stack trace here
      try {
        const ignore = ignoreUrls.some(url =>
          event.exception.values[0].stacktrace.frames.some(frame =>
            frame.filename.startsWith(url)
          )
        )
        if (ignore) return null
      } catch (oO) {}

      return event
    },
    ignoreErrors: [
      "AbortError",
      "NotAllowedError",
      /* https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios */
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: cancelled",
      "Cannot read property 'Player' of undefined", // Youtube embed error
      "Cannot read properties of undefined (reading 'Player')", // Youtube embed error
      "undefined is not an object (evaluating 'new b.Player')" // Youtube embed error
    ],
    denyUrls: [
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /^safari-extension:\/\//i
    ]
  })
}
