import ApplicationController from "./application_controller"

/**
 * GTM Publisher reference
 * https://developers.google.com/publisher-tag/reference
 */

export default class extends ApplicationController {
  static values = {
    adOptions: Object,
    adSize: Array,
    adSizeMapping: Array,
    adNative: Array
  }

  static classes = ["empty"]

  connect() {
    this.currentWidth = this.desiredWidth
  }

  disconnect() {
    this.destroy()
    this.clearTimer()
  }

  show() {
    if (!this.slot) {
      googletag.cmd.push(() => {
        this.slot = googletag.defineSlot(this.adOptionsValue.unitPath, this.adSizeValue, this.element.id)
        const [slotName, slotNumber] = this.element.id.split("-") // assuming its "<slotName>-<slotNumber>"

        this.slot.setTargeting("slotname", slotName)
        this.slot.setTargeting("slotnumber", slotNumber)
        this.slot.addService(googletag.pubads())

        googletag.enableServices()
        googletag.display(this.element.id)
      })
    }
  }

  destroy() {
    if (this.slot) {
      googletag.cmd.push(() => {
        googletag.destroySlots([this.slot])
      })
      this.slot = undefined
    }
  }

  hide() {
    this.element.classList.add(this.emptyClass)
  }

  resize() {
    const iframe = this.element.querySelector("iframe")
    const id = this.element.id
    if (iframe) {
      if (id.startsWith("cta")) {
        iframe.removeAttribute("height")
        iframe.removeAttribute("width")
      } else if (id.startsWith("stickycta")) {
        const adHeight = 50
        const adHeightPx = `${adHeight}px`
        const spacer = "--spacer-sticky-ad"
        // cta ads are responsive, but are targetted by having dimensions of 3x2.
        // This overrides the dummy values with a value based on the width of the window
        // We are also using the same ad spot to show 320x50 banner ads, which are fixed size
        // so we only want to recalculate the height and width for the CTAs.
        // TODO(aspinks): Is there a way we clean this up when we redo the CTA creatives?
        if (iframe.width === "3" && iframe.height === "2") {
          iframe.width = window.innerWidth - 32
          iframe.height = adHeight
        }
        this.element.style.height = adHeightPx
        document.documentElement.style.setProperty(spacer, adHeightPx)
      }
    }
  }

  refresh() {
    this.clearTimer()

    if (this.slot && this.currentWidth !== this.desiredWidth) {
      this.setTimer()
    }
  }

  setTimer() {
    this.debounceTimer = setTimeout(() => {
      googletag.cmd.push(() => {
        googletag.pubads().refresh([this.slot])
      })

      this.currentWidth = this.desiredWidth
      this.debounceTimer = undefined
    }, 250)
  }

  clearTimer() {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer)
    }
  }

  get desiredWidth() {
    return this.adSizeMappingValue
      .map(([[width]]) => width)
      .sort((a, b) => b - a)
      .find((w) => w <= window.innerWidth)
  }
}
