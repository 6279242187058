import ApplicationController from "./application_controller"
import IMask from "imask"

/**
 * Make select input controller
 *
 * Controls the submenu visibility and
 * scrollability responsively.
 *
 * @since 23.10
 */
export default class extends ApplicationController {
  static targets = [
    "button",
    "modal",
    "form",
    "card",
    "terms",
    "successView",
    "termsView",
    "phone",
    "fields",
    "successMessage",
    "successTitle"
  ]

  static classes = ["hidden", "flip"]

  static values = {
    endpoint: String,
    channel: String,
    token: String,
    referrer: String
  }

  opened = false
  flipped = false
  phoneMask = null
  connect() {
    window.addEventListener("keydown", this.escapeKeyClose.bind(this))
    this.modalTarget.addEventListener("click", this.clickOutside.bind(this))
    this.formTarget.addEventListener("submit", this.submit.bind(this))
    if (this.phoneTarget) {
      this.phoneMask = IMask(this.phoneTarget, {
        mask: [
          {
            mask: "+00 000 000 000",
            startsWith: "61"
          },
          {
            mask: "0000 000 000",
            startsWith: "04"
          },
          {
            mask: "00 0000 0000",
            startsWith: ""
          }
        ],
        dispatch: (appended, dynamicMasked) => {
          const number = (dynamicMasked.value + appended).replace(/\D/g, "")

          return dynamicMasked.compiledMasks.find((m) => number.indexOf(m.startsWith) === 0)
        }
      })
    }
  }

  disconnect() {
    window.removeEventListener("keydown", this.escapeKeyClose)
    this.modalTarget.removeEventListener("click", this.clickOutside)
    this.phoneMask?.destroy()
    this.formTarget.removeEventListener("submit", this.submit)
  }

  clickOutside(event) {
    if (event.target === this.modalTarget) {
      this.modalTarget.removeEventListener("click", this.clickOutside)
      this.close()
    }
  }

  escapeKeyClose(event) {
    if (this.opened && event.key === "Escape") {
      this.close()
    }
  }

  flip() {
    this.cardTarget.classList[this.flipped ? "remove" : "add"](this.flipClasses)
    this.flipped = !this.flipped
  }

  acceptTerms() {
    this.termsTarget.checked = true
  }

  declineTerms() {
    this.termsTarget.checked = false
  }

  close() {
    this.modalTarget.classList.add(this.hiddenClasses)
    this.opened = false
    this.successViewTarget.classList.add("hidden")
    this.termsViewTarget.classList.remove("hidden")
    if (this.flipped) {
      this.flip()
    }
    this.reset()
  }

  reset() {
    this.formTarget.reset()
    this.fields().forEach((target) => {
      if (target.nextSibling.classList.contains("error")) {
        target.nextSibling.classList.add("hidden")
        target.nextSibling.innerText = ""
        target.classList.remove("border-red-500")
      }
    })
  }

  open() {
    this.modalTarget.classList.remove(this.hiddenClasses)
    this.opened = true
  }

  fields() {
    return [this.phoneTarget, this.termsTarget, ...this.fieldsTargets]
  }

  async submit(event) {
    event.preventDefault()
    const payload = {
      referrer: this.referrerValue
    }

    this.fields().forEach((target) => {
      const value = target.name === "message" && target.value?.trim()?.length === 0 ? target.placeholder : target.value
      Object.assign(payload, { [target.name]: value })
    })

    const response = await fetch(`${this.endpointValue}/${this.channelValue}`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Token": this.tokenValue
      },
      body: JSON.stringify(payload)
    })

    if (response.ok) {
      const { title, message } = await response.json()

      this.successMessageTarget.innerHTML = message
      this.successTitleTarget.innerText = title
      this.successViewTarget.classList.remove("hidden")
      this.termsViewTarget.classList.add("hidden")
      this.flip()
    } else {
      const { errors } = await response.json()

      this.fields().forEach((target) => {
        const error = errors[target.name]?.pop() ?? false
        if (error) {
          target.classList.add("border-red-500")
          target.nextSibling.innerText = error
          target.nextSibling.classList.remove("hidden")
        }
      })
    }
  }
}
