import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  initialize () {
    this.closeEverythingElse = this.closeEverythingElse.bind(this)
  }

  connect () {
    for (let i = 0; i < this.details.length; i++) {
      this.details[i].addEventListener("click", this.closeEverythingElse)
    }
  }

  disconnect () {
    for (let i = 0; i < this.details.length; i++) {
      this.details[i].removeEventListener("click", this.closeEverythingElse)
    }
  }

  closeEverythingElse (event) {
    for (let i = 0; i < this.details.length; i++) {
      if (!this.details[i].contains(event.target)) {
        this.details[i].open = false
      }
    }
  }

  get details () {
    return this.element.getElementsByTagName("details")
  }
}
