export const workaroundWebkitBug = () => {
  /* workaround for Safari bug https://github.com/ausi/respimagelint/issues/31 */
  /* force images to be re-evaluated, else it ignores srcset */
  const sources = document.querySelectorAll("img[srcset],source[srcset]")
  for (let i = 0; i < sources.length; i++) {
    if (sources[i].tagName === "IMG") { sources[i].src += "" }
    sources[i].srcset += ""
    sources[i].sizes += ""
  }
}
