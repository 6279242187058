import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["content"]
  static classes = ["toggled"]

  add (event) {
    if (event) event.preventDefault()
    this.contentTarget.classList.add(this.toggledClass)
  }

  remove (event) {
    if (event) event.preventDefault()
    this.contentTarget.classList.remove(this.toggledClass)
  }

  toggle () {
    this.contentTarget.classList.toggle(this.toggledClass)
  }

  handleKeyDown (event) {
    if (event.key === "Escape") {
      this.remove()
    }
  }

  turboBeforeCache () {
    this.remove()
  }
}
