import ApplicationController from "./application_controller"

const scriptId = "disqus-script"

export default class extends ApplicationController {
  static values = {
    identifier: String,
    shortname: String,
    url: String
  }

  connect () {
    if (this.isPreview || this.hasFeature("no-comments")) return

    this.cleanChildren()

    const { identifierValue, shortnameValue, urlValue } = this

    // https://help.disqus.com/en/articles/1717084-javascript-configuration-variables
    window.disqus_shortname = shortnameValue
    window.disqus_config = function () {
      this.page.identifier = identifierValue
      this.page.url = urlValue
    }

    if (window.DISQUS) {
      window.DISQUS.reset({ reload: true })
    } else {
      this.addScript()
    }
  }

  turboBeforeCache () {
    this.cleanScript()
    this.cleanChildren()
  }

  addScript () {
    const script = window.document.createElement("script")
    script.async = true
    script.src = `https://${this.shortnameValue}.disqus.com/embed.js`
    script.id = scriptId
    window.document.head.appendChild(script)
  }

  cleanScript () {
    const script = window.document.getElementById(scriptId)
    if (script) {
      window.document.head.removeChild(script)
    }
  }

  cleanChildren () {
    while (this.element.hasChildNodes()) {
      this.element.removeChild(this.element.firstChild)
    }
  }
}
