const track = (event) => {
  window.dataLayer.push(event)
}

const gtmContainerId = window.config?.googletag?.channel ?? null

export const gtmTrack = track

export const setupGtm = () => {
  track({
    event: "gtm.js",
    "gtm.start": new Date().getTime()
  })
}

export const setGtmPageViewEvent = () => {
  if (gtmContainerId && window.pageViewEvent?.page) {
    window.pageViewEvent.page.pageInfo.sysEnv = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
      ? "mobile"
      : "desktop"
    window.pageViewEvent.timestamp = new Date().getTime()
    // reset dataLayer on every page view to prevent residue data from spilling from one page to the next
    window.dataLayer.push(() => {
      window.google_tag_manager[gtmContainerId].dataLayer.reset()
    })
    track(window.pageViewEvent)
  }
}

export const virtualPageView = ({ virtualPageUrl, virtualPageTitle }) => {
  track({
    event: "VirtualPageview",
    virtualPageTitle,
    virtualPageUrl
  })
}
