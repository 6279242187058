import ApplicationController from "./application_controller"
import { useClickOutside } from "stimulus-use"

/**
 * Primary Menu Controller.
 *
 * Controls the submenu visibility and
 * scrollability responsively.
 *
 * @since 22.12
 */
export default class extends ApplicationController {
  static classes = ["hide", "noscroll", "active", "inactive"]

  static values = {
    showScrollDown: Boolean,
    scrollStep: Number
  }

  static targets = ["menu", "submenu", "iconExpand", "iconClose"]

  connect() {
    useClickOutside(this)
    this.bodyElement = document.querySelector("body")
  }

  clickOutside() {
    this.hideAll()
  }

  maybeEnableBodyScroll() {
    this.showScrollDownValue ? this.bodyElement.classList.add(this.noscrollClass) : this.disableBodyScroll()
    return this
  }

  disableBodyScroll() {
    this.bodyElement.classList.remove(this.noscrollClass)
    this.showScrollDownValue = false
    return this
  }

  onScroll(event) {
    this.showScrollDownValue = event.currentTarget.scrollTop > 0
    this.maybeEnableBodyScroll()
  }

  toggle(event) {
    event.preventDefault()
    const menu = event.currentTarget
    this.isMenuExpanded(menu) ? this.hideAll() : this.hideAll().maybeEnableBodyScroll().toggleMenuItem(menu, true)
  }

  hideAll() {
    this.disableBodyScroll()
    this.menuTargets.forEach((menu) => this.toggleMenuItem(menu, false))
    return this
  }

  toggleMenuItem(menu, show = false) {
    menu.setAttribute("aria-expanded", show ? "true" : "false")
    if (show) {
      menu.classList.add(...this.activeClasses)
      menu.classList.remove(...this.inactiveClasses)
      this.toggleMenuIcon(menu, show).toggleSubMenu(menu, show)
    } else {
      menu.classList.remove(...this.activeClasses)
      menu.classList.add(...this.inactiveClasses)
      this.toggleMenuIcon(menu, false).toggleSubMenu(menu, show)
      menu.blur()
    }
  }

  toggleSubMenu(menu, show = false) {
    const submenu = this.submenuTargets.find((submenu) => submenu.id === menu.getAttribute("aria-controls"))?.classList

    show ? submenu?.remove(this.hideClass) : submenu?.add(this.hideClass)

    return this
  }

  toggleMenuIcon(menu, show = false) {
    const close = this.iconCloseTargets.find((icon) => icon.parentElement === menu)?.classList
    const expand = this.iconExpandTargets.find((icon) => icon.parentElement === menu)?.classList
    if (show) {
      expand?.add(this.hideClass)
      close?.remove(this.hideClass)
    } else {
      expand?.remove(this.hideClass)
      close?.add(this.hideClass)
    }
    return this
  }

  isMenuExpanded(menu) {
    return menu.getAttribute("aria-expanded") === "true"
  }
}
