import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["submit"]

  connect () {
    this.submitTarget.style.display = "none"
  }

  disconnect () {
    delete this.submitTarget.style.display
  }

  autoSubmit () {
    this.submitTarget.click() // alternative to form.requestSubmit()
  }
}
