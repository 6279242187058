import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  initialize() {
    this.actionedId = null
  }

  connect() {
    this.element.addEventListener("click", this.onClickInside)
    window.addEventListener("click", this.onClickOutside.bind(this))
    this.hideAll()
  }

  disconnect() {
    this.element.removeEventListener("click", this.onClickInside)
    window.removeEventListener("click", this.onClickOutside)
  }

  onClickInside(event) {
    event.stopImmediatePropagation()
  }

  onClickOutside() {
    // this.hideAll()
  }

  toggle(event) {
    const actionedId = event.currentTarget.getAttribute("aria-controls")
    const actioned = document.getElementById(actionedId)

    this.hideAll()

    if (this.actionedId !== actionedId) {
      this.show(actioned)
      this.actionedId = actionedId
    } else {
      this.actionedId = null
    }
  }

  show(answer) {
    answer.classList.remove("hidden")

    const button = answer.previousElementSibling.children[0]
    button.setAttribute("aria-expanded", true)
    button.children[1]?.classList.add("rotate-180")
  }

  hide(answer) {
    answer.classList.add("hidden")

    const button = answer.previousElementSibling.children[0]
    button.setAttribute("aria-expanded", false)
    button.children[1]?.classList.remove("rotate-180")
  }

  hideAll() {
    this.answers = this.answers ?? this.element.querySelectorAll("section[data-controller='faq'] dd")
    for (const answer of this.answers) {
      this.hide(answer)
    }
  }
}
