import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = { content: String }

  connect () {
    import("tippy.js").then((module) => {
      const tippy = module.default

      tippy(this.element, {
        content: this.contentValue,
        placement: "top-start"
      })
    })
  }
}
