function strictEncode (str) {
  return encodeURIComponent(str).replace(/[!'()]/g, escape).replace(/\*/g, "%2A")
}

export function getCookie (cName) {
  let i, x, y
  const c = document.cookie.split(";")
  for (i = 0; i < c.length; i++) {
    x = c[i].substr(0, c[i].indexOf("="))
    y = c[i].substr(c[i].indexOf("=") + 1)
    x = x.replace(/^\s+|\s+$/g, "")
    if (x === cName) {
      return unescape(y)
    }
  }
}

export function getKey (cName) {
  let c = getCookie(cName)
  c = strictEncode(c)
  if (typeof c !== "undefined" && c.match(/\w+%3D/)) {
    const cList = c.split("%3D")
    if (typeof cList[0] !== "undefined" && cList[0].match(/\w+/)) {
      return cList[0]
    }
  }
}

export function getValues (cName) {
  let c = getCookie(cName)
  c = strictEncode(c)
  if (typeof c !== "undefined" && c.match(/\w+%3D\w+/)) {
    const cList = c.split("%3D")
    if (typeof cList[1] !== "undefined" && cList[1].match(/\w+/)) {
      const vList = cList[1].split("%2C")
      if (typeof vList[0] !== "undefined") {
        return vList
      } else {
        return null
      }
    } else {
      return null
    }
  } else {
    return null
  }
}
