import ApplicationController from "./application_controller"
import { useClickOutside } from "stimulus-use"

/**
 * Make select input controller
 *
 * Controls the submenu visibility and
 * scrollability responsively.
 *
 * @since 22.12
 */
export default class extends ApplicationController {
  static targets = ["button", "list"]
  static classes = ["hidden"]

  connect() {
    useClickOutside(this)
  }

  clickOutside() {
    this.close()
  }

  toggle() {
    if (this.buttonTarget.getAttribute("aria-expanded") === "false") {
      this.open()
    } else {
      this.close()
    }
  }

  close() {
    this.listTarget.classList.add(this.hiddenClasses)
    this.buttonTarget.setAttribute("aria-expanded", "false")
  }

  open() {
    this.listTarget.classList.remove(this.hiddenClasses)
    this.buttonTarget.setAttribute("aria-expanded", "true")
  }
}
