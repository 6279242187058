import ApplicationController from "./application_controller"

/**
 * Search Modal window Controller.
 *
 * Controls the submenu visibility and
 * scrollability responsively.
 *
 * @since 22.12
 */
export default class extends ApplicationController {
  static classes = ["hidden"]

  static targets = ["modal", "input"]

  opened = false

  connect() {
    window.addEventListener("keydown", this.escapeKeyClose.bind(this))
    this.modalTarget.addEventListener("click", this.clickOutside.bind(this))
  }

  clickOutside(event) {
    if (event.target === this.modalTarget) {
      this.modalTarget.removeEventListener("click", this.clickOutside)
      this.close()
    }
  }

  escapeKeyClose(event) {
    if (this.opened && event.key === "Escape") {
      this.close()
    }
  }

  open() {
    this.modalTarget.classList.remove(...this.hiddenClasses)
    this.inputTarget.focus()
    this.opened = true
  }

  close() {
    this.modalTarget.classList.add(...this.hiddenClasses)
    this.opened = false
    this.inputTarget.value = ""
  }
}
