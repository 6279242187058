import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    openAt: Number
  }

  open () {
    const event = new CustomEvent("gallery:open", { detail: this.openAtValue })
    window.document.dispatchEvent(event)
  }
}
