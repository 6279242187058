import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect () {
    // Most adblockers use an injected style to hide a div that it thinks is an ad
    // At least one adblocker (Ghostery) seems to do this a bit late in the rendering cycle after the stimulus controller has connected
    // Adding an empty timeout seems to give enough time for the stylesheet to be applied.
    setTimeout(() => {
      const mockAdStyle = window.getComputedStyle(this.element, null)

      if (mockAdStyle.getPropertyValue("display") === "none" || mockAdStyle.getPropertyValue("visibility") === "hidden") {
        this.pushEvent({
          event: "adblock-detected"
        })
      } else {
        this.pushEvent({
          event: "adblock-whitelisted"
        })
      }
    }, 0)
  }

  pushEvent (eventObj) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(eventObj)
  }
}
