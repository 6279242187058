import ApplicationController from "./application_controller"
/**
 * Tab Controller.
 *
 * Tab component management.
 *
 * @since 23.11
 */
export default class extends ApplicationController {
  static targets = ["tab", "content"]
  static classes = ["activetab", "inactivetab", "toggleContent"]

  toggle(event) {
    this.tabTargets.forEach((element, i) => {
      if (event.currentTarget !== element) {
        element.classList.remove(...this.activetabClasses)
        element.classList.add(...this.inactivetabClasses)
        this.contentTargets[i].classList.add(this.toggleContentClass)
      } else {
        element.classList.add(...this.activetabClasses)
        element.classList.remove(...this.inactivetabClasses)
        this.contentTargets[i].classList.remove(this.toggleContentClass)
      }
    })
  }
}
