import ApplicationController from "./application_controller"
import { gtmTrack, virtualPageView } from "../helpers/gtm"
import { jwUrl } from "../services/jwplatform"

const LINK_ID = "lightgallery-css"
const LG_CONTAINER = ".lg-container"

export default class extends ApplicationController {
  static values = { dynamicEl: Array, adOptions: Object }

  connect () {
    // lg.destroy() is async, so does not clean up before the DOM is cached by Turbo
    // if loading from Turbo cache, we need to ensure a clean slate
    document.querySelectorAll(LG_CONTAINER).forEach((e) => e.parentNode.removeChild(e))

    if (this.isPreview) return

    const link = window.document.createElement("link")
    link.rel = "stylesheet"
    link.href = "/assets/lg-2.2.1/css/lightgallery-bundle.min.css"
    link.id = LINK_ID
    window.document.head.appendChild(link)
  }

  disconnect () {
    if (this.gallery) {
      this.gallery.destroy()
    }
  }

  async open (event) {
    if (this.isPreview) return

    const slide = event.type === "gallery:open" ? event.detail : 0
    if (this.gallery) {
      this.gallery.openGallery(slide)
    } else {
      const self = this

      const slides = await Promise.all(
        this.dynamicElValue.map(async (slide) => {
          if (slide.jwId) {
            let updatedSlide
            try {
              const src = await jwUrl(slide.jwId)
              updatedSlide = {
                ...slide,
                video: {
                  source: [
                    {
                      src,
                      type: "video/mp4"
                    }
                  ],
                  attributes: {
                    preload: "none",
                    controls: true
                  }
                }
              }
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(error)
            }
            return updatedSlide
          } else {
            return slide
          }
        })
      )

      require(["video.js"], (videojs) => {
        // lg-video.js expects a global method to be available
        // And video.js is packaged with commonjs require("video.js").default
        window.videojs = videojs.default
        require([
          "lightgallery",
          "lightgallery/plugins/fullscreen",
          "lightgallery/plugins/thumbnail",
          "lightgallery/plugins/video",
          "lightgallery/plugins/zoom"
        ], (lightGallery, lgFullscreen, lgThumbnail, lgVideo, lgZoom) => {
          self.gallery = lightGallery.default(self.element, {
            /* global */
            container: document.body,
            dynamic: true,
            dynamicEl: slides.filter(Boolean),
            licenseKey: "NFA4E-FUF98-3C2M7-UECC2",
            mobileSettings: {
              controls: true,
              showCloseIcon: true,
              download: true
            },
            plugins: [lgFullscreen.default, lgThumbnail.default, lgVideo.default, lgZoom.default],

            /* zoom plugin */
            actualSize: false,
            showZoomInOutIcons: true,

            /* video plugin */
            videojs: true,
            videojsOptions: {
              autoplay: true,
              preload: "auto"
            },
            /* thumbnails plugin */
            allowMediaOverlap: true,
            toggleThumb: true,
            thumbWidth: 96, // [sic] this is a number
            thumbHeight: "76px" // [sic] this is a string
          })

          self.gallery.openGallery(slide)
        })
      }, (error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
    }
  }

  galleryAdPlacement () {
    return document.getElementById("gallerybanner-1")
  }

  onBeforeOpen () {
    this.isGalleryOpenEvent = true
  }

  onAfterOpen () {
    if (this.isVideo()) {
      setTimeout(() => {
        this.hideThumbs()
      }, 3000)
    }

    if (this.hasSpaceForAds()) {
      this.galleryAdPlacement()?.classList?.remove("advertisement--empty")
      window.document.dispatchEvent(new CustomEvent("gallery:showad"))
    }
  }

  hideThumbs () {
    this.gallery.outer.firstElement.classList.remove("lg-components-open")
  }

  hasSpaceForAds () { return this.aspectRatio() < 0.9 }
  aspectRatio () { return window.innerWidth / window.innerHeight }

  isVideo () {
    const { index, galleryItems } = this.gallery
    return !!galleryItems[index].videoId
  }

  isPortraitImage () {
    if (this.isVideo()) {
      return false
    }
    const image = document.querySelector(".lg-current img")
    if (!image) {
      return false
    }
    return image.naturalHeight > image.naturalWidth
  }

  onAfterSlide (event) {
    const idx = event.detail.index
    virtualPageView({ virtualPageUrl: window.location.pathname, virtualPageTitle: document.title })
    gtmTrack({
      event: this.isGalleryOpenEvent ? "galleryOpen" : "galleryImageChange",
      eventInfo: {
        galleryName: document.title,
        carInfo: {
          totalImages: this.dynamicElValue.length,
          image: this.dynamicElValue[idx].src,
          imageIndex: idx
        }
      }
    })
    this.isGalleryOpenEvent = false
  }

  onAfterItemLoad () {
    if (this.isPortraitImage()) {
      this.removeAd()
    }
  }

  removeAd () {
    this.galleryAdPlacement()?.classList?.add("advertisement--empty")
    window.document.dispatchEvent(new CustomEvent("googletag:destroy"))
  }

  beforeClose () {
    this.removeAd()
  }

  resize () {
    this.removeAd()
  }

  turboBeforeCache () {
    const link = window.document.getElementById(LINK_ID)
    if (link) {
      window.document.head.removeChild(link)
    }
  }
}
