import ApplicationController from "./application_controller"

// Will push GTM events when scroll has reached specified depths.
/**
 * Usage:
 * You will need to specify where the content starts and ends in order to get
 * the measurement points. Use the data attributes `data-scroll-depth-target="start"`
 * and `data-scroll-depth-target="end"`.
 *
 * ```
 * <div data-controller="scroll-depth" data-scroll-depth-title-value="My title">
 *   <SillyAd/>
 *   <div data-scroll-depth-target="start">Great article</div> <--- Indicates the start of the content
 *  <SillyAd/>
 *  <div>Article body....</div>
 *  <SomeOtherImportantContent/>
 *  <SillyAd/>
 *  <div data-scroll-depth-target="end" />  <--- Indicates the end/100% of the content
 *   <SomeContentWeDontCareAbout/>
 * </div>
 * ```
 */

export default class extends ApplicationController {
  static values = {
    title: String
  }

  static targets = ["start", "end", "title"]

  initialize () {
    this.scroll = this.scroll.bind(this)

    this.depths = [0.25, 0.5, 0.75, 1]
    this.depthsTriggered = []
  }

  connect () {
    document.addEventListener("scroll", this.scroll)
  }

  disconnect () {
    document.removeEventListener("scroll", this.scroll)
  }

  scroll () {
    const percentageScrolled = this.percentageScrolled
    this.depths.forEach((depth) => {
      const hasAlreadyTriggered = this.hasTriggered(depth)

      if (percentageScrolled > depth && !hasAlreadyTriggered) {
        this.triggerEvent(depth)
      }
    })
  }

  triggerEvent (depth) {
    this.pushEvent({
      event: "article-milestone",
      article_title: this.titleValue,
      scroll_measure_percent: `${depth * 100}%`,
      scroll_measure_value: depth * 100
    })

    this.recordAsTriggered(depth)
  }

  recordAsTriggered (depth) {
    this.depthsTriggered.push(depth)
  }

  hasTriggered (depth) {
    return this.depthsTriggered.includes(depth)
  }

  pushEvent (eventObj) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(eventObj)
  }

  get percentageScrolled () {
    const contentDistanceFromTop = this.startTarget.offsetTop
    const contentEndDistanceFromTop = this.endTarget.offsetTop + this.endTarget.offsetHeight
    const heightOfScrollArea = contentEndDistanceFromTop - contentDistanceFromTop
    const currentScrollPosition = document.documentElement.scrollTop
    const browserHeight = document.documentElement.clientHeight

    const distanceOverContent = currentScrollPosition - contentDistanceFromTop + browserHeight

    return distanceOverContent / heightOfScrollArea
  }
}
