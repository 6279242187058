import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["go", "input", "make", "model", "preview", "search"]
  static classes = ["buttonEmpty", "inputInactive", "inputValue", "selectInactive", "selectValue"]

  connect () {
    this.disableGo()

    if ("IntersectionObserver" in window) {
      this.observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          this.inputTarget.focus()
        }
      })

      this.observer.observe(this.inputTarget)
    }
  }

  disconnect () {
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  disableGo () {
    this.goTarget.disabled = true
    this.makeTarget.classList.add(this.selectInactiveClass)
    this.modelTarget.classList.add(this.selectInactiveClass)
    this.makeTarget.classList.remove(this.selectValueClass)
    this.modelTarget.classList.remove(this.selectValueClass)
  }

  disableSearch () {
    this.searchTarget.disabled = true
    this.inputTarget.classList.add(this.inputInactiveClass)
    this.inputTarget.classList.remove(this.inputValueClass)
  }

  enableGo () {
    this.makeTarget.classList.remove(this.selectInactiveClass)
    this.modelTarget.classList.remove(this.selectInactiveClass)
    if (this.makeTarget.value) {
      this.disableSearch()
      this.goTarget.disabled = false
      this.makeTarget.classList.add(this.selectValueClass)
      if (this.modelTarget.value) {
        this.modelTarget.classList.add(this.selectValueClass)
      }
    }
  }

  enableSearch () {
    this.disableGo()
    this.validateText()
    this.inputTarget.classList.remove(this.inputInactiveClass)
    this.searchTarget.disabled = false
  }

  handleKeyDown (event) {
    if (this.element.contains(event.target) && event.key === "Enter") {
      event.preventDefault()
      // one of these buttons will always be disabled, so pressing Enter will only trigger the active one
      this.searchTarget.click()
      this.goTarget.click()
    }
  }

  turboBeforeCache () {
    this.makeTarget.value = ""
    this.modelTarget.innerHTML = ""
    this.modelTarget.disabled = true
  }

  updateModels () {
    this.enableGo()
    this.previewTarget.value = "1"
    this.goTarget.click()
    this.previewTarget.value = ""
  }

  validateText () {
    if (this.inputTarget.value) {
      this.inputTarget.classList.add(this.inputValueClass)
      this.searchTarget.classList.remove(this.buttonEmptyClass)
    } else {
      this.searchTarget.classList.add(this.buttonEmptyClass)
    }
  }
}
