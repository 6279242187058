import ApplicationController from "./application_controller"
/**
 * Search Car Make and Model Controller.
 *
 * Controls the submenu visibility and
 * scrollability responsively.
 *
 * @since 22.12
 */
export default class extends ApplicationController {
  static targets = ["make", "model", "go", "preview"]

  turboBeforeCache() {
    this.makeTarget.value = ""
    this.modelTarget.innerHTML = ""
    this.modelTarget.disabled = true
  }

  submit() {
    this.element.requestSubmit()
  }

  updateModels() {
    this.previewTarget.value = "1"
    this.element.requestSubmit()
    this.previewTarget.value = ""
  }
}
