import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["brand", "list", "menu", "search"]
  static classes = ["brandToggled", "listToggled", "menuToggled", "searchToggled"]

  menuOpen () {
    this.menuTarget.classList.add(this.menuToggledClass)
  }

  menuClose () {
    this.brandTarget.classList.remove(this.brandToggledClass)
    this.listTarget.classList.remove(this.listToggledClass)
    this.menuTarget.classList.remove(this.menuToggledClass)
    this.searchTarget.classList.remove(this.searchToggledClass)
  }

  searchOpen () {
    this.brandTarget.classList.add(this.brandToggledClass)
    this.listTarget.classList.add(this.listToggledClass)
    this.menuTarget.classList.add(this.menuToggledClass)
    this.searchTarget.classList.add(this.searchToggledClass)
  }

  handleKeyDown (event) {
    if (event.key === "Escape") {
      this.menuClose()
    }
  }

  turboBeforeCache () {
    this.menuClose()
  }
}
