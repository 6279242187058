const teadsMobileHeight = 201
const teadsDesktopHeight = 331
const mediumBreakpoint = 768

export const messageListener = (event) => {
  if (event.origin.endsWith("safeframe.googlesyndication.com") && event.data === "teads:initialised") {
    const iframeEl = window.document.querySelector("#articlebanner-1 iframe")
    if (iframeEl) {
      iframeEl.height = window.innerWidth < mediumBreakpoint ? teadsMobileHeight : teadsDesktopHeight
    }
  }
}
