import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["copyLink"]
  static values = {
    copyLinkDefault: String
  }

  resetOnClose () {
    if (!this.element.hasAttribute("open") && this.hasCopyLinkTarget && this.hasCopyLinkDefaultValue) {
      this.copyLinkTarget.innerHTML = this.copyLinkDefaultValue
    }
  }

  closeWhenClickedElsewhere (event) {
    // Close the details element if a click occurred and it wasn't on the element we're attached to (or its children)
    if (!this.element.contains(event.srcElement)) {
      this.element.removeAttribute("open")
    }
  }

  handleKeyDown (event) {
    if (event.key === "Escape") {
      this.element.removeAttribute("open")
    }
  }
}
