import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["main", "next", "pagination", "prev"]
  static values = {
    slideCount: Number
  }

  async connect() {
    if (this.isPreview) {
      return
    }

    try {
      const { default: Swiper, Navigation, Pagination } = await import("swiper")

      this.slider = new Swiper(this.mainTarget, {
        modules: [Navigation, Pagination],
        // Setting loop true will repeat slides - don't repeat slides if there is less than 4 slides to show.
        loop: this.slideCountValue >= 4,
        navigation: {
          nextEl: this.nextTarget,
          prevEl: this.prevTarget
        },
        pagination: {
          el: this.paginationTarget,
          clickable: true
        },
        slidesPerView: 1.2,
        spaceBetween: 16,
        breakpoints: {
          768: { slidesPerView: 2.2 },
          1024: { slidesPerView: 3.2 },
          1350: { slidesPerView: 4.2 }
        }
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  disconnect() {
    if (this.slider) {
      this.slider.destroy(true, false)
    }
  }
}
