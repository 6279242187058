import ApplicationController from "./application_controller"

// Locks scroll on visibility
export default class extends ApplicationController {
  connect () {
    const self = this
    let isLeaving = false
    let scrollTop
    let originalStyles

    if ("IntersectionObserver" in window) {
      self.cssText = undefined
      this.observer = new IntersectionObserver(function (entries) { // eslint-disable-line compat/compat
        if (entries[0].isIntersecting) {
          isLeaving = true
          originalStyles = self.cssText
          scrollTop = self.scrollTop
          self.cssText = "overflow: hidden; position: fixed; height: 100%; width: 100%;"
        } else if (isLeaving) {
          isLeaving = false
          self.cssText = originalStyles
          self.scrollTop = scrollTop
        }
      })

      this.observer.observe(this.element)
    }
  }

  get cssText () {
    return document.body.style.cssText
  }

  set cssText (value) {
    document.body.style.cssText = value
  }

  get scrollTop () {
    return document.documentElement.scrollTop
  }

  set scrollTop (value) {
    document.documentElement.scrollTop = value
  }

  disconnect () {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}
