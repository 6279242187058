const lightSections = "landing__section--light"
const darkSections = "landing__section--dark"
const lightBackground = "--brand-light-background"
const darkBackground = "--brand-dark-background"

const truskinClassMatch = (className) => (node) => node.classList && node.classList.contains(className)
const truskinIdMatch = (node) => /^coming-[\d]+$/.test(node.id)

const applyFnToClass = (selector, fn) => Array.from(document.getElementsByClassName(selector)).forEach(fn)

const truskinSet = () => {
  applyFnToClass(lightSections, el => el.style.setProperty(lightBackground, "white"))
  applyFnToClass(darkSections, el => el.style.setProperty(darkBackground, "black"))
}

const truskinUnset = () => {
  applyFnToClass(lightSections, el => el.style.removeProperty(lightBackground))
  applyFnToClass(darkSections, el => el.style.removeProperty(darkBackground))
}

// addedNodes: HTMLCollection
const isDesktopTruskin = ({ addedNodes }) => {
  return [...addedNodes].some(node =>
    truskinClassMatch("frameElement")(node) &&
    [...node.childNodes].some(truskinClassMatch("bz-ad-container"))
  )
}

// addedNodes: HTMLCollection
const isMobileTruskin = ({ addedNodes }) => {
  return [...addedNodes].some(node =>
    truskinClassMatch("page-type-container")(node) &&
    [...node.childNodes].some(truskinIdMatch)
  )
}

const truskinMutationHandler = (mutations) => {
  const anyTruskinMatch = mutations.reduce((acc, mutation) => {
    if (isDesktopTruskin(mutation) || isMobileTruskin(mutation)) {
      return true
    }

    return acc
  }, false)

  if (anyTruskinMatch) {
    truskinSet()
  }
}

export const pruneBonzai = () => {
  if (typeof window.bonzai !== "undefined" && typeof window.bonzai.destroy === "function") {
    truskinUnset()
    window.bonzai.destroy()
  }
}

let bonzaiObserver

export const setupTruskinObserver = () => {
  const bodyEl = document.body
  const config = { childList: true }
  bonzaiObserver = bonzaiObserver || new MutationObserver(truskinMutationHandler)
  // disconnect the old observer that might have gone stale
  bonzaiObserver.disconnect()
  bonzaiObserver.observe(bodyEl, config)
}
