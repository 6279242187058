import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["submit"]

  connect () {
    if (!this.hasSubmitTarget) return

    this.handleChange = this.handleChange.bind(this)
    this.submitTarget.disabled = true
    for (let i = 0; i < this.formElements.length; i++) {
      this.formElements[i].addEventListener("change", this.handleChange)
    }
  }

  disconnect () {
    if (!this.hasSubmitTarget) return

    for (let i = 0; i < this.formElements.length; i++) {
      this.formElements[i].removeEventListener("change", this.handleChange)
    }
  }

  handleChange () {
    // naive toggle to enable button
    // it doesn't try to remember previous values
    this.submitTarget.disabled = false
  }

  get formElements () {
    return this.element.elements
  }
}
