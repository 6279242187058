import ApplicationController from "./application_controller"
import * as Turbo from "@hotwired/turbo"
/**
 * TableLinksController.
 *
 * For adding link navigation to table rows.
 *
 * @since 23.11
 */
export default class extends ApplicationController {
  static targets = ["expandable", "actionLabel"]

  connect() {
    this.element.addEventListener("click", this.stopClickPropagation)
  }

  disconnect() {
    this.element.removeEventListener("click", this.stopClickPropagation)
  }

  stopClickPropagation(event) {
    event.stopPropogation()
  }

  click(event) {
    const link = event.currentTarget.dataset.link
    if (link) {
      Turbo.visit(link)
    }
  }

  expand() {
    this.expandableTarget.classList.toggle("hidden")

    const isExpanded = !this.expandableTarget.classList.contains("hidden")

    this.expandableTarget.setAttribute("aria-expanded", isExpanded)

    this.actionLabelTarget.innerText = isExpanded
      ? this.actionLabelTarget.dataset.expandedLabel
      : this.actionLabelTarget.dataset.collapsedLabel
  }
}
